<template>
  <div class="main">
    <NavBar/>
    <!-- Loading -->
    <div v-if="loading" class="content">
      <div class="loader m-auto"></div>
      <strong v-if="isSubmitted" class="m-auto mt-8">กำลังดำเนินการ กรุณารอสักครู่</strong>
    </div>

    <!-- Content -->
    <div v-else class="content">
      <form v-on:submit.prevent="submit">
        <!-- Header -->
        <div class="row">
          <div v-if="announcement.id" class="header">แก้ไขรายละเอียดประกาศ<span class="ml-2">{{ header }}</span></div>
          <p v-else class="project-header">เพิ่มประกาศ</p>
        </div>

        <!-- Warning -->
        <div class="text-red-500">*กรุณาตรวจสอบว่ามีรายวิชาที่สอดคล้องกับข้อมูลที่เลือกภายในระบบก่อน (อื่น ๆ -> ตั้งค่ารายวิชา) มิเช่นนั้นจะไม่สามารถสร้างหรือแก้ไขประกาศได้*</div>
        
        <!-- Academic year -->
        <div class="form-div mt-8">
          <p class="label -ml-4">ปีการศึกษา</p>
          <select v-model="announcement.year" class="w-6/12">
            <option v-for="year in years" :key="year.id" :value="year">{{ year }}</option>
          </select>
        </div>

        <!-- Semester -->
        <div class="form-div mt-8">
          <p class="label -ml-4">ภาคเรียนที่</p>
          <select v-model="announcement.semester" class="w-6/12">
            <option>1</option>
            <option>2</option>
          </select>
        </div>

        <!-- Course type -->
        <div class="form-div mt-8 mb-4">
          <p class="label -ml-4">รายวิชา</p>
          <select v-model="announcement.type" @change="changeType(announcement.type)" class="col-span-2">
            <option value="PJ">โครงงาน</option>
            <option value="CP">สหกิจศึกษา</option>
            <option value="IS">ศึกษาอิสระ</option>
          </select>
        </div>

        <!-- Program -->
        <div class="form-div mt-8">
          <p class="label -ml-4">หลักสูตร</p>
          <select v-model="announcement.program" class="col-span-2">
            <option v-for="program in programs" :key="program.id" :value="program.id">{{ program.name }}</option>
          </select>
        </div>

        <!-- Course: not found Error -->
        <p class="text-error" v-if="val.course">ไม่พบรายวิชาที่สอดคล้องกับข้อมูลที่เลือกภายในระบบ</p>
        <hr>

        <!-- Header (Announcement) -->
        <div class="form-div mt-8">
          <p class="label -ml-4">หัวข้อประกาศ</p>
          <div class="col-span-4 mb-12">
            <div class="flex space-x-8" id="my-toolbar">
              <div>
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-strike"></button>
              </div>
              <div>
                <select class="ql-color"></select>
                <select class="ql-background"></select>
              </div>
            </div>
            <quill-editor v-model:content="announcement.header" toolbar="#my-toolbar" contentType="html" theme="snow" id="text-header"></quill-editor>
          </div>
        </div>

        <!-- Header: null Error -->
        <p v-if="val.header" class="text-error">กรุณากรอกข้อมูลสำหรับ "หัวข้อประกาศ"</p>

        <!-- Description -->
        <div class="form-div mt-8">
          <p class="label -ml-4">รายละเอียด</p>
          <div class="col-span-4 mb-12">
            <div class="flex space-x-8" id="my-toolbar-2">
              <select class="ql-size">
                <option value="small"></option>
                <option selected></option>
                <option value="large"></option>
                <option value="huge"></option>
              </select>
              <div>
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-strike"></button>
              </div>
              <div>
                <select class="ql-color"></select>
                <select class="ql-background"></select>
              </div>
              <div>
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
              </div>
            </div>
            <quill-editor v-model:content="announcement.description" toolbar="#my-toolbar-2" contentType="html" theme="snow" id="text-description"></quill-editor>
          </div>
        </div>

        <!-- Description: null Error -->
        <p v-if="val.description" class="text-error">กรุณากรอกข้อมูลสำหรับ "รายละเอียด"</p>

        <!-- Upload file -->
        <div class="form-div">  
          <p class="label my-auto -ml-4">เอกสารที่เกี่ยวข้อง</p>
          <div class="upload-button">
            <label for="file" class="file-button">อัปโหลด</label>
            <input type="file" ref="file" id="file" name="file" multiple @change="handleFileUpload($event)" class="w-0">
          </div>
        </div>

        <!-- Upload file list -->
        <div v-if="files" class="form-div">
          <p class="label my-auto -ml-4"></p>
          <div class="col-span-4">
            <ul>
              <li v-for="file in files" :key="file.id">
                <span v-on:click="removeFile(file)" class="label text-delete">ลบ</span>
                <span class="inline-block" style="min-width: 20rem">{{ file.name }}</span>
              </li>
            </ul>
          </div>
        </div>

        <hr>
        <button type="submit" value="submit" class="submit-button" :disabled="isSubmitted">บันทึกข้อมูล</button>
        <div v-if="validate_error" class="text-red-500 mt-4">พบข้อผิดพลาดในข้อมูลที่กรอก กรุณาตรวจสอบข้อมูลอีกครั้ง (มีการแสดงข้อผิดพลาดภายใต้ข้อมูลที่กรอก)</div>
      </form>
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar"
import { QuillEditor } from "@vueup/vue-quill"
import "@vueup/vue-quill/dist/vue-quill.snow.css"
import { announcementValidate } from "../mixins/validate"
import { changeProgramName, changeProgramByType, fileUpload, academicYear } from "../mixins/other"

export default {
  name: "AnnouncementEdit",
  components: {
    NavBar,
    QuillEditor
  },
  data () {
    return {
      loading: true,
      isSubmitted: false,
      announcement: {
        id: null,
        header: "",
        description: "",
        type: "PJ",
        semester: 1,
        year: new Date().getFullYear() + 543,
        program: 1,
        flag: "A",
      },
      val: {
        pass: false,
        course: false,
        header: false,
        description: false
      },
      files: [],
      file_name: [],
      programs: [],
      years: [],
      header: null,
      role: null
    }
  },
  async created() {
    this.role = this.$store.getters.payload.role
    // Permission
    if (this.role !== "STF") {
      this.$router.push("/")
    }
    this.courses = await this.$store.dispatch("getCourse")
    this.programs = await this.$store.dispatch("getProgram")
    this.programs = changeProgramName(changeProgramByType(this.announcement.type))
    this.years = academicYear(this.years)
    const annId = this.$route.params.id

    // Edit announcement
    if (annId) {
      this.announcement = await this.$store.dispatch("getAnnouncementById", annId)
      this.files = this.announcement.file
      this.announcement.file_id = []
      this.announcement.all_file_id = []
      for(let f in this.files) {
        this.file_name.push(this.files[f].name)
        this.announcement.file_id.push(this.files[f].id)
        this.announcement.all_file_id.push(this.files[f].id)
      }
      const course = await this.$store.dispatch("getCourseById", this.announcement.course)
      this.announcement['year'] = course.year
      this.announcement['semester'] = course.semester
      this.announcement['program'] = course.program
      this.announcement['type'] = course.type
      this.programs = changeProgramName(changeProgramByType(this.announcement.type))
      this.header = this.announcement.header.replace(/<\/?[^>]+(>|$)/g, "")
      document.title = this.header + " | Carbon"
    } 
    
    // Create announcement
    else {
      document.title = "เพิ่มประกาศ | Carbon"
    }

    this.loading = false
  },
  methods: {
    async submit() {
      if(!this.isSubmitted) {
        this.isSubmitted = true
        this.loading = true
        const data = this.announcement
        this.announcement.file = this.files
        this.announcement.file_name = this.file_name
        announcementValidate(data, this.courses, this.val)

        // Create announcement
        if (this.announcement.id === null && this.val.pass) {
          const ann = await this.$store.dispatch("createAnnouncement", data)
          this.$router.push(`/announcement/${ann.id}`)
        } 

        // Edit announcement
        else if (this.announcement.id !== null && this.val.pass) {
          await this.$store.dispatch("updateAnnouncement", data)
          this.$router.push(`/announcement/${this.announcement.id}`)
        } 

        // Error
        else {
          this.validate_error = true
        }

        this.isSubmitted = false
        this.loading = false
      }
    },
    handleFileUpload(event) {
      let upload = fileUpload(event, this.files, this.file_name)
      this.files = upload.files
      this.file_name = upload.file_name
    },
    removeFile(fileSelect) {
      this.files = Array.from(this.files).filter(function (file) {
        return file !== fileSelect
      })
      var index = this.file_name.indexOf(fileSelect.name)
      if (index > -1) {
        this.file_name.splice(index, 1)
      }
        
      if (this.announcement.id) {
        if (index > -1) {
          this.announcement.file_id.splice(index, 1)
        }
      }
    },
    changeType(type) {
      this.programs = changeProgramName(changeProgramByType(type))
      if (type == 'IS') {
        this.announcement.program = 4
      } else {
        this.announcement.program = 1
      }
    }
  }
}
</script>